// extracted by mini-css-extract-plugin
export var button = "process-module--button--63e0d";
export var buttonContainer = "process-module--button-container--7d81c";
export var container = "process-module--container--744f4";
export var imageContainer = "process-module--image-container--2fbf8";
export var mainImage = "process-module--main-image--0f2c6";
export var step = "process-module--step--a71ad";
export var stepCircle = "process-module--step-circle--5b338";
export var stepImage = "process-module--step-image--113ce";
export var stepImageContainer = "process-module--step-image-container--2aad3";
export var stepNumber = "process-module--step-number--78978";
export var stepTextContainer = "process-module--step-text-container--d1cd2";
export var stepTitle = "process-module--step-title--11643";
export var stepsContainer = "process-module--steps-container--ac07c";
export var title = "process-module--title--e8795";