import React from "react"
import Layout from "../components/layouts/Layout.js"
import * as styles from "../stylesheets/pages/process.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"

const Process = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicOurProcess {
        data {
          image {
            alt
            url
          }
          step {
            step_description {
              richText
            }
            step_icon {
              alt
              url
            }
            step_title {
              text
            }
          }
          title {
            text
          }
        }
      }
    }
  `).prismicOurProcess.data

  const steps = data.step.map((step, idx) => {
    return (
      <div className={styles.step} key={idx}>
        <div className={styles.stepImageContainer}>
          <div className={styles.stepCircle}>
            <img
              src={step.step_icon.url}
              alt={step.step_icon.alt}
              className={styles.stepImage}
            />
          </div>
        </div>
        <div className={styles.stepTextContainer}>
          <h2 className={styles.stepTitle}>{step.step_title.text}</h2>
          <RichText
            render={step.step_description.richText}
            serializeHyperlink={GatsbyLink}
          />
          <h1 className={styles.stepNumber}>{idx + 1}</h1>
        </div>
      </div>
    )
  })

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={data.image.url} alt="" className={styles.mainImage} />
          <h1 className={styles.title}>Our Process</h1>
        </div>
        <div className={styles.stepsContainer}>
          {steps}
          <div className={styles.buttonContainer}>
            <Link to="/boston">
              <button className={styles.button}>BOSTON</button>
            </Link>
            <Link to="/sanfrancisco">
              <button className={styles.button}>SAN FRANCISCO</button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Process
